import PropTypes from 'prop-types';
import useI18n from '../../lib/use_i18n';
import { Notification } from '../common/notification';
import { Flex } from '../common/styles/flex';
import { Wrapper } from './wrapper';

export const NoSession = ({
  allowCookiesArticleUrl,
  clientUrl,
  goHiringUrl,
  host,
  notificationProps,
}) => {
  const { translate } = useI18n('access');
  return (
    <Wrapper
      goHiringUrl={goHiringUrl}
      notificationProps={notificationProps}
      subtitle={translate('.no_session_subtitle')}
      title={translate('.no_session_title')}
    >
      <h2>{translate('.allow_cookies')}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('.enable_cookies_instructions', {
            host: `<strong>${host}</strong>`,
          }),
        }}
      />

      <a rel="noreferrer" href={allowCookiesArticleUrl} target="_blank">
        {translate('.allow_cookies_link')}
      </a>
      {clientUrl && (
        <Flex marginTop="32px" justifyContent="flex-end">
          <a className={'btn btn-primary'} href={clientUrl}>
            {translate('.back_to_recruiting_system')}
          </a>
        </Flex>
      )}
    </Wrapper>
  );
};
NoSession.propTypes = {
  allowCookiesArticleUrl: PropTypes.string,
  clientUrl: PropTypes.string,
  goHiringUrl: PropTypes.string,
  host: PropTypes.string,
  notificationProps: PropTypes.shape(Notification.propTypes),
};
NoSession.defaultProps = {
  host: window.location.hostname,
};
