import PropTypes from 'prop-types';
import { useState } from 'react';
import { handleApiResponse } from '../../lib/jsonapi';
import useI18n from '../../lib/use_i18n';
import { requestResetPassword } from '../../store';
import { Form, InputField } from '../common/form';
import { Notification } from '../common/notification';
import { Flex } from '../common/styles/flex';
import { FormRow, RowsWrapper } from '../common/styles/form';
import { Wrapper } from './wrapper';
import { SIGN_IN_URL } from './sign_in';

export const RequestResetPassword = ({ goHiringUrl, notificationProps }) => {
  const [showNotification, setShowNotification] = useState(false);
  const { translate } = useI18n('access');
  return (
    <Wrapper
      goHiringUrl={goHiringUrl}
      notificationProps={notificationProps}
      subtitle={translate('.request_reset_password_subtitle')}
      title={translate('.request_reset_password_title')}
    >
      <Form
        onSubmit={async (values) => {
          const errors = await handleApiResponse(
            requestResetPassword(values),
            () => setShowNotification(true)
          );
          return errors;
        }}
      >
        {({ handleSubmit, submitting }) => {
          return (
            <>
              {showNotification && (
                <Notification
                  kind="success"
                  className="buffer-bottom-2"
                  title={translate(
                    '.request_reset_password_notification.title'
                  )}
                  text={translate('.request_reset_password_notification.text')}
                />
              )}
              <RowsWrapper>
                <FormRow>
                  <InputField
                    customMessage={translate('.form.email.error')}
                    label={translate('.form.email.label')}
                    name="email"
                    required={true}
                    type="email"
                  />
                </FormRow>
              </RowsWrapper>
              <Flex
                alignItems=""
                marginTop="16px"
                justifyContent="space-between"
              >
                <button
                  disabled={submitting}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                  className="btn btn-primary"
                >
                  {translate('.form.request_reset_password')}
                </button>
                <a href={SIGN_IN_URL}>{translate('.back_to_login')}</a>
              </Flex>
            </>
          );
        }}
      </Form>
    </Wrapper>
  );
};

RequestResetPassword.propTypes = {
  signInUrl: PropTypes.string,
  goHiringUrl: PropTypes.string,
  notificationProps: PropTypes.shape(Notification.propTypes),
};
