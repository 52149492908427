import PropTypes from 'prop-types';
import { handleApiResponse } from '../../lib/jsonapi';
import useI18n from '../../lib/use_i18n';
import { resetPassword } from '../../store';
import { removeEmptyKeys } from '../../utils';
import { Form, InputField } from '../common/form';
import { Notification } from '../common/notification';
import { Flex } from '../common/styles/flex';
import { FormRow, RowsWrapper } from '../common/styles/form';
import { Wrapper } from './wrapper';
import { SIGN_IN_URL } from './sign_in';

export const ResetPassword = ({
  activationToken,
  goHiringUrl,
  url,
  userEmail,
  submitButtonId,
}) => {
  const { translate } = useI18n('access');
  return (
    <Wrapper
      goHiringUrl={goHiringUrl}
      title={translate('.reset_password_title')}
      subtitle={translate('.reset_password_subtitle')}
    >
      {userEmail && (
        <Notification
          kind="warning"
          className="buffer-bottom-2"
          text={translate('.reset_password_notification.text', { userEmail })}
        />
      )}
      <Form
        onSubmit={async (values) => {
          const errors = await handleApiResponse(
            resetPassword({
              values: removeEmptyKeys({ ...values, token: activationToken }),
              url,
            }),
            ({ headers }) =>
              window.location.assign(headers?.location ?? SIGN_IN_URL)
          );
          return errors;
        }}
      >
        {({ handleSubmit, submitting }) => {
          return (
            <>
              <RowsWrapper>
                <FormRow>
                  <InputField
                    label={translate('.form.password.label')}
                    name="password"
                    required={true}
                    type="password"
                  />
                </FormRow>
              </RowsWrapper>
              <RowsWrapper>
                <FormRow>
                  <InputField
                    label={translate('.form.password_confirmation.label')}
                    name="password_confirmation"
                    required={true}
                    type="password"
                  />
                </FormRow>
              </RowsWrapper>
              <Flex
                alignItems=""
                marginTop="16px"
                justifyContent="space-between"
              >
                <button
                  disabled={submitting}
                  id={submitButtonId}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className="btn btn-primary"
                >
                  {translate('.form.reset_password')}
                </button>
              </Flex>
            </>
          );
        }}
      </Form>
    </Wrapper>
  );
};

ResetPassword.propTypes = {
  activationToken: PropTypes.string,
  goHiringUrl: PropTypes.string,
  signInUrl: PropTypes.string,
  url: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  submitButtonId: PropTypes.string,
};
